import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import Filter from "../modules/Filter";
import { getCardTypesWithoutPagination } from "layouts/card_type/services/cardTypeService";
import { getPerFilter, getPerFilterPerFile } from "../services/filterService";
import { v4 as uuidv4 } from "uuid";
import { getStatusTypes } from "layouts/card/services/cardService";
import { getSourceTypes } from "layouts/sources_data/services/dataSourceService";

const FilterController = forwardRef(
  (
    {
      setListData,
      dataFilter,
      dataTable,
      setTotalPages,
      setCurrentPage,
      currentPage,
      requestData,
      setError = () => {},
      setTotalData,
      setEntriesEnd,
      setEntriesStart,
      dataSourceId,
      setLoading = () => {},
    },
    ref
  ) => {
    //Variables del Filtro  ---->
    // setLoading(true);
    const [dataTypeCard, setDataTypeCard] = useState();
    const [dataSourceTypes, setDataSourceTypes] = useState();
    const [dataStatus, setDataStatus] = useState();
    const [dateFrom, setDateFrom] = useState("date");
    const [search, setSearch] = useState("");
    const [dateUntil, setDateUntil] = useState("date");
    const [filter, setFilter] = useState("");
    const [filterDate, setFilterDate] = useState("");
    const [filterType, setFilterType] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleDate, setIsVisibleDate] = useState(false);
    const [isVisibleType, setIsVisibleType] = useState(false);
    const [isVisibleSelectDateInput, setIsVisibleSelectDateInput] = useState(false);
    const [isVisibleSelectDate, setIsVisibleSelectDate] = useState(false);
    const [filters, setFilters] = useState([]);
    const [table, setTable] = useState(dataTable);
    const [ShowErrorAlert, setShowErrorAlert] = useState(true);
    const [paginaSize, setPaginaSize] = useState(10);

    // Ejecutar función requestCarnetsDate con el arreglo de filtros
    const searchValidation = (currentPage, pageSize) => {
      if (dataSourceId) {
        requestFilterPerIdFile(dataSourceId, currentPage, pageSize, filters);
      } else {
        requestFilter(currentPage, pageSize, filters);
      }
    };

    // Esperar respuesta de la función getCarnePerFilterDate con los parametros de filtro, tabla filtrad y variables del renderizado
    const requestFilter = async (pageIndex, pageSize, filters) => {
      try {
        const result = await getPerFilter(pageIndex, pageSize, filters, table);

        if (result && result.status !== false) {
          const { data, last_page, total, from, to } = result;
          await setListData(data);
          await setTotalPages(last_page);
          if (last_page <= pageIndex) {
            await setCurrentPage(last_page);
          }
          await setTotalData(total);
          await setEntriesStart(from);
          await setEntriesEnd(to);
          setLoading(false);
          setError("");
        } else {
          setLoading(false);
          setError("Error al consultar al servidor");
        }
      } catch (error) {
        setLoading(false);
      }
    };

    // Esperar respuesta de la función getCarnePerFilterDate con los parametros de filtro, tabla filtrad y variables del renderizado
    const requestFilterPerIdFile = async (id, pageIndex, pageSize, filters) => {
      try {
        const result = await getPerFilterPerFile(id, pageIndex, pageSize, filters, dataTable);

        if (result.status !== false) {
          const { data, last_page, total, from, to } = result;
          await setListData(data);
          await setTotalPages(last_page);
          if (last_page <= pageIndex) {
            await setCurrentPage(last_page);
          }
          await setTotalData(total);
          await setEntriesStart(from);
          await setEntriesEnd(to);
          setLoading(false);
          setError("");
        } else {
          setLoading(false);
          setError("Error al consultar al servidor");
        }
      } catch (error) {
        setLoading(false);
      }
    };

    //Controla el comportamiento de los filtros segun cual sea elegido
    const handleFilter = (event) => {
      switch (event.target.value) {
        case "":
          setFilter(event.target.value);
          setIsVisible(false);
          setIsVisibleSelectDate(false);
          setIsVisibleDate(false);
          setIsVisibleType(false);
          setIsVisibleSelectDateInput(false);
          setFilterType("");
          setSearch("");
          setFilterDate("");
          setDateFrom("date");
          setDateUntil("date");
          resetFieldValid();
          break;
        case "type_carnet":
        case "status":
        case "source_type_name":
          setFilter(event.target.value);
          setIsVisibleType(true);
          setIsVisible(false);
          setIsVisibleSelectDate(false);
          setIsVisibleDate(false);
          setIsVisibleSelectDateInput(false);
          setSearch("");
          setFilterDate("");
          setDateFrom("date");
          setDateUntil("date");
          resetFieldValid();
          break;
        case "number_id":
        case "name":
        case "email":
        case "user_email":
        case "source_name":
        case "file_name":
          setFilter(event.target.value);
          setIsVisible(true);
          setIsVisibleSelectDate(false);
          setIsVisibleDate(false);
          setIsVisibleType(false);
          setIsVisibleSelectDateInput(false);
          setSearch("");
          setFilterType("");
          setFilterDate("");
          setDateFrom("date");
          setDateUntil("date");
          resetFieldValid();
          break;
        case "created":
        case "created_at":
        case "expires_in":
        case "updated_at":
          setIsVisibleSelectDateInput(true);
          setFilter(event.target.value);
          setFilterDate(event.target.value);
          setIsVisible(false);
          setIsVisibleType(false);
          setFilterType("");
          setSearch("");
          resetFieldValid();
          break;
      }
    };

    //Maneja y valida los filtros que se agregan, si ya existe se reemplaza el antiguo por el nuevo
    const handleAddFilter = (event) => {
      let filterLabel = "";
      if (
        !(
          (filter === "created" ||
            filter === "created_at" ||
            filter === "expires_in" ||
            filter === "updated_at") &&
          dateFrom !== "date" &&
          dateUntil !== "date"
        ) &&
        !search.trim()
      ) {
        handleChangeValid("search", false);
        handleChangeValid("date", false);
        return;
      }

      // Buscar filtro existente con el mismo tipo
      const existingFilterIndex = filters.findIndex((item) => item.type === filter);
      if (existingFilterIndex !== -1) {
        // Reemplazar filtro existente
        const newFilters = [...filters];
        newFilters[existingFilterIndex] = createFilter();
        setFilters(newFilters);
        return;
      }

      // Crear nuevo filtro
      const newFilter = createFilter();
      setFilters([...filters, newFilter]);

      // Restablecer estado
      resetState();

      function createFilter() {
        switch (filter) {
          case "type_carnet":
          case "source_type_name":
            if (!search.trim()) {
              return;
            }
            filterLabel = filter === "type_carnet" ? "Tipo de Carnet: " : "Tipo de Fuente: ";
            filterLabel += search.trim();
            break;
          case "number_id":
            if (!search.trim()) {
              return;
            }
            filterLabel = "Documento: " + search.trim();
            break;
          case "name":
          case "source_name":
          case "file_name":
            if (!search.trim()) {
              return;
            }
            filterLabel = filter === "name" ? "Nombre o Apellido: " : "Nombre: ";
            filterLabel += search.trim();
            break;
          case "email":
          case "status":
          case "user_email":
            if (!search.trim()) {
              return;
            }
            filterLabel =
              filter === "email" || filter === "user_email" ? "Correo Electrónico: " : "ESTADO: ";
            filterLabel += search.trim();
            break;
          case "created":
          case "created_at":
          case "expires_in":
          case "updated_at":
            if (dateFrom === "date" || dateUntil === "date") {
              return;
            }
            switch (filter) {
              case "created":
              case "created_at":
                filterLabel = "Fecha de Generación: ";
                break;
              case "expires_in":
                filterLabel = "Fecha de Vigencia: ";
                break;
              case "updated_at":
                filterLabel = "Fecha de Actualizacion: ";
                break;
              default:
                filterLabel = "Fecha: ";
            }
            filterLabel += dateFrom + " - " + dateUntil;
            break;
          default:
            return;
        }

        return {
          id: uuidv4(),
          type: filter,
          value:
            filter === "created" ||
            filter === "created_at" ||
            filter === "expires_in" ||
            filter === "updated_at"
              ? { from: dateFrom, until: dateUntil }
              : search.trim(),
          label: filterLabel,
        };
      }

      function resetState() {
        setFilter("");
        setIsVisible(false);
        setIsVisibleSelectDate(false);
        setIsVisibleDate(false);
        setIsVisibleType(false);
        setIsVisibleSelectDateInput(false);
        setFilterType("");
        setSearch("");
        setFilterDate("");
        setDateFrom("date");
        setDateUntil("date");
      }
    };

    //Borrar filtro almacenado en el arreglo de filtros
    const handleDeleteFilter = (id) => {
      const updatedFilters = filters.filter((item) => item.id !== id);
      setFilters(updatedFilters);

      if (updatedFilters.length === 0) {
        if (dataSourceId) {
          requestData(id, currentPage, paginaSize);
        } else {
          requestData(currentPage, paginaSize);
        }
      }
    };

    // Hace llamado a la funcion que realiza la peticion de los tipos de carnets y status cuando se renderiza el componente
    useEffect(() => {
      setLoading(true);
      requestCardType();
      requestStatus();
      requestSourceTypes();
    }, []);

    // Hace llamado a la funcion que valida la data con los filtros actuales cuando la pagina actual cambia
    useEffect(() => {
      searchValidation(currentPage, paginaSize);
    }, [currentPage, filters]);

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestCardType = async (pageIndex) => {
      const result = await getCardTypesWithoutPagination();
      if (result && result.status !== false) {
        const { payload } = result;
        await setDataTypeCard(payload);
      } else {
      }
    };

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestSourceTypes = async (pageIndex) => {
      const result = await getSourceTypes();
      if (result && result.status !== false) {
        const { payload } = result;
        await setDataSourceTypes(payload);
      } else {
      }
    };

    //Esperar respuesta de la función getCardTypes y setear  el valor a dataTypeCard
    const requestStatus = async (pageIndex) => {
      const result = await getStatusTypes(pageIndex);
      if (result.status !== false) {
        const { data } = result;
        await setDataStatus(data);
      } else {
      }
    };

    //Mostrar los campos de tipo de carnets para filtrar y ocultar los campos si se selecciona "ninguno"
    const handleFilterType = (event) => {
      handleChangeValid("search", true);
      if (event.target.value === "") {
        setSearch(event.target.value);
        return;
      }
      setSearch(event.target.value);
    };

    //setear los valores digitados del primer filtro
    const handleChange = (event) => {
      event.preventDefault();
      handleChangeValid("search", true);
      const searchIsString = event.target.value;
      if (searchIsString.startsWith(" ")) return;
      setSearch(searchIsString.toString());
      if (filter === "") return;
    };

    //setear los cambios de fecha siempre y cuando la fecha "desde" no sea mayor a la de "hasta" y no este vacio el campo
    const handleChangeDateFrom = (event) => {
      handleChangeValid("date", true);
      if (event.target.value !== "") {
        const date = new Date(event.target.value);
        let formatDate = date.toISOString().slice(0, 10);
        if (dateUntil !== "" && dateUntil !== "date") {
          if (formatDate > dateUntil) {
            formatDate = dateUntil;
          }
        }
        setDateFrom(formatDate);
      } else {
        setDateFrom("date");
      }
    };

    //setear los cambios de fecha siempre y cuando la fecha "hasta" no sea menor a la de "desde" y no este vacio el campo
    const handleChangeDateUntil = (event) => {
      handleChangeValid("date", true);
      if (event.target.value !== "") {
        const date = new Date(event.target.value);
        let formatDate = date.toISOString().slice(0, 10);
        if (dateFrom !== "" && dateFrom !== "date") {
          if (formatDate < dateFrom) {
            formatDate = dateFrom;
          }
        }
        setDateUntil(formatDate);
      } else {
        setDateUntil("date");
      }
    };

    //Ejecutar funcion searchValidation con la página y filas para la tabla de carnets
    const handleClickSubmit = () => {
      handleAddFilter();
      searchValidation(currentPage, paginaSize);
    };

    // Exponer handleClickSubmit al componente padre
    useImperativeHandle(ref, () => ({
      handleClickSubmit,
    }));

    // Validaciones -->

    // Variables de validacion
    const [isFieldValid, setIsFieldValid] = useState({
      search: true,
      date: true,
    });

    // Cambia el valor de la validacion entrante
    const handleChangeValid = (fieldName, isValid) => {
      setIsFieldValid((prevValidity) => ({
        ...prevValidity,
        [fieldName]: isValid,
      }));
    };

    // Setea el estado de todas las validaciones
    const resetFieldValid = () => {
      setIsFieldValid({
        search: true,
        date: true,
      });
    };

    return (
      <>
        <Filter
          dataFilter={dataFilter}
          filter={filter}
          handleFilter={handleFilter}
          isVisible={isVisible}
          handleChange={handleChange}
          search={search}
          isVisibleSelectDate={isVisibleSelectDate}
          filterDate={filterDate}
          handleFilterType={handleFilterType}
          filterType={filterType}
          isVisibleDate={isVisibleDate}
          isVisibleType={isVisibleType}
          isVisibleSelectDateInput={isVisibleSelectDateInput}
          dateFrom={dateFrom}
          dateUntil={dateUntil}
          handleChangeDateFrom={handleChangeDateFrom}
          handleChangeDateUntil={handleChangeDateUntil}
          handleClickSubmit={handleClickSubmit}
          dataTypeCard={dataTypeCard}
          dataStatus={dataStatus}
          dataSourceTypes={dataSourceTypes}
          handleAddFilter={handleAddFilter}
          filters={filters}
          ShowErrorAlert={ShowErrorAlert}
          handleDeleteFilter={handleDeleteFilter}
          isFieldValid={isFieldValid}
        />
      </>
    );
  }
);
export default FilterController;
