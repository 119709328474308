/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React, { useState, useEffect, useRef } from "react";
import ListCarnets from "../modules/ListCarnets";
import {
  getCarnetUserPerFile,
  getTypeCarnets,
  getUserId,
  sendCards,
  updatedCardValidity,
  updatedStatusCarnet,
} from "../services/carnetsPerFileService";
import ViewUserPerCard from "../modules/ViewUserPerCard";
import MDBadge from "components/MDBadge";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import CreditCardOffOutlinedIcon from "@mui/icons-material/CreditCardOffOutlined";
import { ValidityCarnet } from "../modules/ValidityCarnet";
import FilterController from "components/filter/controller/FilterController";
import { CarnetEditedStatus } from "../modules/CarnetEditedStatus";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import usePermission from "hooks/usePermission";

export default function CarnetsPerFileController() {
  const [limitResult, setLimitResult] = useState([
    {
      id: "1",
      status: "active",
      created: "2023-10-23",
      updated_at: "2023-10-24",
      card_user_id: "22",
      card_type_id: "25",
    },
    {
      id: "2",
      status: "active",
      created: "2023-10-23",
      updated_at: "2023-10-24",
      card_user_id: "22",
      card_type_id: "25",
    },
  ]);

  const [userId, setUserId] = useState({});

  //Lógica para la tabla de listar usuarios carnetizados ---->
  const [listCarnets, setListCarnets] = useState();
  const [error, setError] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);
  const [isFilter, setIsFilter] = useState(false);
  const [dataSourceId, setDataSourceId] = useState("");
  const [loading, setLoading] = useState(false);
  const filterControllerRef = useRef();
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const dataTable = "cards"; //Tabla indicada
  const dataFilter = useState([
    {
      field: "DOCUMENTO",
      value: "number_id",
    },
    {
      field: "NOMBRE O APELLIDO",
      value: "name",
    },
    {
      field: "FECHA DE CREACIÓN",
      value: "created",
    },
    {
      field: "FECHA DE VIGENCIA",
      value: "expires_in",
    },
    {
      field: "TIPO DE CARNET",
      value: "type_carnet",
    },
    {
      field: "ESTADO",
      value: "status",
    },
  ]);

  //Esperar respuesta de la función getCarnetUserPerFile y validar respuesta para actualizar la tabla de listado de carnets o mostrar error
  const requestCarnetsPerFile = async (id, pageIndex) => {
    try {
      const result = await getCarnetUserPerFile(id, pageIndex);
      if (result.status !== false) {
        const { data, last_page, total, from, to } = result;
        await setListCarnets(data);
        //await setListCarnets2(data);
        await setTotalPages(last_page);
        if (last_page <= pageIndex) {
          await setCurrentPage(last_page);
        }
        await setTotalData(total);
        await setEntriesStart(from);
        await setEntriesEnd(to);
        setError("");
      } else {
        setError("Error al consultar al servidor");
      }
    } catch (error) {
      setError("Error al consultar al servidor");
    }
  };
  //Cambia el valor de currentPage a la página elegida
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //Enviar correos
  const handleSendCard = async (value) => {
    try {
      const response = await sendCards(dataSourceId, value);
      return response;
    } catch (error) {
      return {
        status: false,
        message: error.message || "Error en la operación",
      };
    }
  };

  //Lógica para el modal de vista ---->
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [viewData, setViewData] = useState({});
  const [typeCarnets, setTypeCarnets] = useState([]);

  // //Esperar respuesta de la función getTypeCarnets y validar respuesta para setear los datos retornados
  const requestTypeCarnets = async () => {
    try {
      const result = await getTypeCarnets();
      const { data } = result;
      setTypeCarnets(data);
    } catch (error) {}
  };

  //abre modal de ver detalles de carnet y setea los valores extra de dicho carnet para visualización
  const handleOpenToModal = async (event) => {
    event.preventDefault();
    const { dataset } = event.currentTarget;
    // await requestTypeCarnets();
    const data = {
      status: dataset.status,
      created: new Date(dataset.created).toISOString().slice(0, 10),
      expired: new Date(dataset.expired).toISOString().slice(0, 10),
      name_user: dataset.name,
      last_name_user: dataset.lastname,
      number_id_user: dataset.dni,
      email_user: dataset.email,
      type_card: dataset.type,
      data_source: dataset.datasource,
      blood_type: dataset.bloodtype,
    };
    setViewData(data);
    setIsOpenModal(true);
  };

  //cierra modal de ver detalles del carnet
  const handleCloseToModal = (event) => {
    event.preventDefault();
    setIsOpenModal(false);
  };
  //Obtener valor por clave especifica del objeto
  const getFieldValue = (fieldName) => {
    return viewData[fieldName];
  };
  // <-------

  //Lógica para crear ---->

  // <-------

  //Lógica para editar ---->

  // <-------

  //Lógica para eliminar ---->

  // <-------

  //Calcula cuantas filas debe mostrarse de la página especifica y ejecuta funcion searchValidation con la pagina y filas determinadas
  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    await setEntriesStart(currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1);
    //await requestCarnets(currentPage, pageSize);
    // searchValidation(currentPage, pageSize);
  };
  /*
  const requestUserPerId = async (idUser) => {
    try {
      const user = await getUserId(idUser);
      const { id, name, lastname, number_id, email, created_at } = user;
      await setUserId({
        id: id,
        name: name,
        lastname: lastname,
        number_id: number_id,
        email: email,
        created: created_at,
      });
      return { id, name, lastname, number_id, email, created_at };
    } catch (error) {
    }
  };
  */

  // cambio de vigencia de carnets --------->

  const [selected, setSelected] = useState([]);
  const [editedDataValidity, setEditedDataValidity] = useState({});
  const [openValidity, setOpenValidity] = useState(false);
  const [isFormModified, setIsFormModified] = useState(false);

  //Esperar respuesta de la función updatedCardValidity y validar respuesta para actualizar tabla o mostrar error
  const requestUpdateValidityCard = async (editValidityCard) => {
    const result = await updatedCardValidity(editValidityCard);

    if (result.status !== false) {
      console.log("Actualizo la lista");
      //requestCarnetsPerFile(dataSourceId, currentPage);
      handleAlert("success", "Vigencia actualizada correctamente");
      callHandleClickSubmit();
    } else {
      handleAlert("fail", result.message || "Error al realizar la operación");
      setError("Error al consultar al servidor");
    }
  };

  //cerrar modal de cambio de vigencia de carnets
  const handleCloseEditValidity = () => setOpenValidity(false);

  //Obtener valor por clave especifica del objeto
  const getFieldValueEditValidity = (fieldName) => {
    return editedDataValidity[fieldName];
  };

  //Cambiar valor por clave y valor especifico del objeto
  const handleFieldChangeEditValidity = (fieldName, value) => {
    setEditedDataValidity({
      ...editedDataValidity,
      [fieldName]: value,
    });
  };

  //ejecuta la funcion requestUpdateValidity con la información necesaria y cierra el modal de actualizar vigencia
  const handleSubmitEditValidity = async (e) => {
    e.preventDefault();
    setLoading(true);
    await requestUpdateValidityCard(editedDataValidity);
    handleCloseEditValidity();
    setLoading(false);
  };

  //abre el modal para cambiar la fecha de vigencia a un grupo de carnets
  const handleOpenValidity = (event) => {
    event.preventDefault();
    setOpenValidity(true);
    setIsDisabled(false);
    //const { dataset } = event.currentTarget;
    const data = {
      expires_in: "",
      idArray: selected,
    };
    //setDefaultData(data);
    setEditedDataValidity(data);
  };

  // *********Se implementó en el filterController******
  // useEffect(() => {
  //   requestCarnets(currentPage, paginaSize);
  // }, [currentPage]);

  useEffect(() => {
    const currentUrl = window.location.href;
    const segments = currentUrl.split("/");
    const dataSourceId = segments[segments.length - 1];
    setDataSourceId(dataSourceId);
    setIsFilter(true);
  }, []);

  // Comprueba si algún campo ha cambiado desde los valores iniciales(vacio o nulo)
  useEffect(() => {
    const requiredFields = ["expires_in"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !editedDataValidity[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [editedDataValidity]);

  //Cambio de estado de carnets ------>
  const [openEditedStatus, setOpenEditedStatus] = useState(false);
  const [editStatusCarnet, setEditStatusCarnet] = useState({});
  const [idCarnet, setIdCarnet] = useState("");

  // cerrar modal de cambio de estado de carnets
  const handleCloseEditedStatus = () => setOpenEditedStatus(false);

  const requestEditStatusCarnet = async (id, statusCarnet) => {
    try {
      const result = await updatedStatusCarnet(id, statusCarnet);
      if (result.status !== false) {
        // const { data, last_page, total, from, to } = result;
        // await setListCarnets(data);
        //requestCarnetsPerFile(dataSourceId, currentPage);
        callHandleClickSubmit();
        handleCloseEditedStatus();
        handleAlert("success", "Estado actualizado correctamente");
        setError("");
      } else {
        handleAlert("fail", result.message || "Error al realizar la operación");
        setError("Error al consultar al servidor");
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al realizar la operación");
      setError("Error al consultar al servidor");
    }
  };

  const handleEditStatusCarnet = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { dataset } = event.currentTarget;
    const statusEdited = dataset.status !== "Activo" ? "Activo" : "Inactivo";
    const data = {
      status: statusEdited,
    };
    setIdCarnet(dataset.idcarnet);
    setEditStatusCarnet(data);
    setOpenEditedStatus(true);
    setLoading(false);
  };

  const handleSubmitEditedStatusCarnet = async (e) => {
    e.preventDefault();
    setLoading(true);
    await requestEditStatusCarnet(idCarnet, editStatusCarnet);
    setLoading(false);
  };
  //<--------

  //mapeo de las filas que se listaran en la tabla de carnets
  const rows = listCarnets
    ? listCarnets.map((carnet) => {
        return {
          id: carnet.id,
          dni: carnet.number_id,
          name: `${carnet.card_user_name} ${carnet.card_user_lastname}`,
          card_type_name: carnet.card_type_name,
          created: new Date(carnet.created).toISOString().slice(0, 10),
          expired_in: new Date(carnet.expires_in).toISOString().slice(0, 10),
          status:
            carnet.status === "Activo" ? (
              <MDBadge badgeContent="Activo" size="xs" container color="success" />
            ) : (
              <MDBadge badgeContent={carnet.status} size="xs" container color="error" />
            ),
          blood_type: carnet.blood_type,
          actions: (
            <>
              <a
                data-dni={carnet.number_id}
                data-name={carnet.card_user_name}
                data-lastname={carnet.card_user_lastname}
                data-status={carnet.status}
                data-created={carnet.created}
                data-expired={carnet.expires_in}
                data-type={carnet.card_type_name}
                data-datasource={carnet.data_source_name}
                data-userid={carnet.card_user_id}
                data-cardtypeid={carnet.card_type_id}
                data-bloodtype={carnet.blood_type}
                style={{ cursor: "pointer" }}
                onClick={handleOpenToModal}
              >
                <FindInPageOutlinedIcon
                  titleAccess="Detalles"
                  style={{
                    fontSize: "200px",
                    width: "24px",
                    height: "24px",
                    backgroundImage: "#000000",
                  }}
                ></FindInPageOutlinedIcon>
                {/*<img src={verDetallesImg} />*/}
              </a>
              {carnet.status === "Inactivo" ? (
                <a
                  style={{
                    cursor: "pointer",
                    pointerEvents: "none",
                    cursor: "not-allowed",
                    textDecoration: "none",
                    color: "inherit",
                    marginLeft: "8px",
                    opacity: "0.5",
                  }}
                >
                  <DownloadIcon
                    titleAccess="Descargar Carnet"
                    style={{
                      fontSize: "200px",
                      width: "24px",
                      height: "24px",
                      backgroundImage: "#000000",
                    }}
                  />
                </a>
              ) : (
                <a
                  href={`${process.env.REACT_APP_API_URL}/cards/card_file/${carnet.id}`}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                    marginLeft: "8px",
                  }}
                >
                  <DownloadIcon
                    titleAccess="Descargar Carnet"
                    style={{
                      fontSize: "200px",
                      width: "24px",
                      height: "24px",
                      backgroundImage: "#000000",
                    }}
                  />
                </a>
              )}
              {carnet.status === "Activo" ? (
                <a
                  data-dni={carnet.number_id}
                  data-name={carnet.card_user_name}
                  data-lastname={carnet.card_user_lastname}
                  data-status={carnet.status}
                  data-created={carnet.created}
                  data-expired={carnet.expires_in}
                  data-type={carnet.card_type_name}
                  data-userid={carnet.card_user_id}
                  data-cardtypeid={carnet.card_type_id}
                  data-idcarnet={carnet.id}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={handleEditStatusCarnet}
                >
                  <CreditCardOffOutlinedIcon
                    style={{
                      fontSize: "200px",
                      width: "24px",
                      height: "24px",
                      backgroundImage: "#000000",
                    }}
                    titleAccess="Desactivar carnet"
                  ></CreditCardOffOutlinedIcon>
                </a>
              ) : (
                <a
                  data-dni={carnet.number_id}
                  data-name={carnet.card_user_name}
                  data-lastname={carnet.card_user_lastname}
                  data-status={carnet.status}
                  data-created={carnet.created}
                  data-expired={carnet.expires_in}
                  data-type={carnet.card_type_name}
                  data-userid={carnet.card_user_id}
                  data-cardtypeid={carnet.card_type_id}
                  data-idcarnet={carnet.id}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={handleEditStatusCarnet}
                >
                  <CreditCardOutlinedIcon
                    style={{
                      fontSize: "200px",
                      width: "24px",
                      height: "24px",
                      backgroundImage: "#000000",
                    }}
                    titleAccess="Activar carnet"
                  ></CreditCardOutlinedIcon>
                </a>
              )}
            </>
          ),
        };
      })
    : [];

  //Guarda o borra la id de la fila seleccionada o desceleccionada en el array selected
  const handleSelect = (id) => {
    const newSelected = [...selected];
    const selectedIndex = selected.indexOf(id);

    if (selectedIndex === -1) {
      newSelected.push(id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }
    setSelected(newSelected);
  };

  //Selecciona todas las filas de una pagina y las guarda o borra(si es desceleccionada) en el array selected
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((row) => row.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  //<---------
  return (
    <>
      <Spinner loading={loading}></Spinner>
      {usePermission("Card.edit.validity") && (
        <>
          {isFilter && (
            <FilterController
              data={listCarnets}
              dataFilter={dataFilter}
              dataTable={dataTable}
              setListData={setListCarnets}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setTotalPages={setTotalPages}
              requestData={requestCarnetsPerFile}
              setError={setError}
              setTotalData={setTotalData}
              setEntriesEnd={setEntriesEnd}
              setEntriesStart={setEntriesStart}
              dataSourceId={dataSourceId}
              setLoading={setLoading}
              ref={filterControllerRef}
            ></FilterController>
          )}
        </>
      )}
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <ListCarnets
        listCarnets={listCarnets}
        handleSendCard={handleSendCard}
        limitResult={limitResult}
        error={error}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        paginaSize={paginaSize}
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        handleOpenToModal={handleOpenToModal}
        selected={selected}
        handleSelect={handleSelect}
        handleSelectAll={handleSelectAll}
        handleOpenValidity={handleOpenValidity}
        rows={rows}
      ></ListCarnets>
      <ViewUserPerCard
        isOpenModal={isOpenModal}
        handleCloseToModal={handleCloseToModal}
        isDisabled={isDisabled}
        typeCarnets={typeCarnets}
        getFieldValue={getFieldValue}
      ></ViewUserPerCard>
      <ValidityCarnet
        open={openValidity}
        handleClose={handleCloseEditValidity}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueEditValidity}
        handleFieldChange={handleFieldChangeEditValidity}
        handleSubmit={handleSubmitEditValidity}
        selected={selected}
      ></ValidityCarnet>
      <CarnetEditedStatus
        openEditedStatus={openEditedStatus}
        handleCloseEditedStatus={handleCloseEditedStatus}
        infoStatus={editStatusCarnet}
        handleSubmitDelete={handleSubmitEditedStatusCarnet}
      ></CarnetEditedStatus>
    </>
  );
}
