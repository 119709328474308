import { Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import MDButton from "components/MDButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MDBadge from "components/MDBadge";
import usePermission from "hooks/usePermission";

export default function UserList({
  handleUserCreate,
  handleUserList,
  handleUserEdit,
  handleUserDelete,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
}) {
  // Verificar permisos
  const canCreate = usePermission("User.create");
  const canEdit = usePermission("User.edit");
  const canDelete = usePermission("User.delete");
  const canListUser = usePermission("User.all");
  // const canListParticipant = usePermission("Listar", "Participantes");
  return (
    <>
      {canCreate && (
        <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
          <MDButton onClick={handleUserCreate} variant="gradient" color="info">
            Crear
          </MDButton>
        </MDBox>
      )}
      {canListUser && (
        <>
          <DataTable
            table={{
              columns: [
                { Header: "correo electrónico", accessor: "email" },
                { Header: "nombre", accessor: "name" },
                { Header: "apellido", accessor: "lastname" },
                { Header: "rol", accessor: "role" },
                { Header: "estado", accessor: "status" },
                { Header: "Acciones", accessor: "actions" },
              ],
              rows: handleUserList
                ? handleUserList.map((userMan) => {
                    return {
                      id: userMan.id,
                      email: userMan.email,
                      name: userMan.name,
                      lastname: userMan.lastname,
                      role: userMan.role.name,
                      status:
                        userMan.status === "Activo" ? (
                          <MDBadge badgeContent="Activo" size="xs" container color="success" />
                        ) : (
                          <MDBadge badgeContent="Inactivo" size="xs" container color="error" />
                        ),
                      actions: (
                        <>
                          {/*<a href="#">
                        <img src={verDetallesImg} />
                      </a>*/}
                          {canEdit && (
                            <a
                              data-id={userMan.id}
                              data-name={userMan.name}
                              onClick={handleUserEdit}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            >
                              {/*<img src={verTablaImg}></img>*/}
                              {
                                <EditOutlinedIcon
                                  titleAccess="Editar"
                                  style={{
                                    fontSize: "200px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: "#000000",
                                  }}
                                />
                              }
                            </a>
                          )}
                          {canDelete && (
                            <a
                              data-id={userMan.id}
                              data-name={userMan.name}
                              onClick={handleUserDelete}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            >
                              {/*<img src={verTablaImg}></img>*/}
                              {
                                <DeleteForeverOutlinedIcon
                                  titleAccess="Eliminar"
                                  style={{
                                    fontSize: "200px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: "#000000",
                                  }}
                                />
                              }
                            </a>
                          )}
                        </>
                      ),
                    };
                  })
                : [],
            }}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
          />
          {!handleUserList ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}
