/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";
import verDetallesImg from "assets/images/ver_detalles.svg";
import React, { useState, useEffect, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import MDBadge from "components/MDBadge";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Icon,
  Checkbox,
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  CircularProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDPagination from "components/MDPagination";
import MDTypography from "components/MDTypography";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import { date } from "yup";
import { position } from "stylis";
import { red } from "@mui/material/colors";
import MDAlert from "components/Alert";
import usePermission from "hooks/usePermission";
export default function ListCarnets({
  listCarnets,
  dataTypeCard,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  paginaSize,
  currentPage,
  totalPages,
  handlePageChange,
  handleOpenToModal,
  handleSelect,
  handleSelectAll,
  selected,
  handleOpenValidity,
  handleSendCard,
  rows,
  handleRequestDownload,
}) {
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("fail");
  // Enviar correos para todos los usuarios
  const handleSendMailForAll = async () => {
    try {
      await handleSendCard("false");
      setAlertType("success");
      setAlertMessage("Operación completada con éxito.");
      setIsAlert(true);
    } catch (error) {
      setAlertType("fail");
      setAlertMessage(`Error en la operación: ${error.message}`);
      setIsAlert(true);
    }
  };

  // Enviar correos para  usuarios no notificados
  const handleSendMail = async () => {
    try {
      const response = await handleSendCard("true");
      console.log(response?.message);
      if (response && response.status == 200) {
        setAlertType("success");
        setAlertMessage("Operación completada con éxito.");
        setIsAlert(true);
      } else {
        setAlertType("fail");
        setAlertMessage(`${response.message}`);
        setIsAlert(true);
      }
    } catch (error) {
      setAlertType("fail");
      setAlertMessage(`${error.message}`);
      setIsAlert(true);
    }
  };

  const canValidityCarnet = usePermission("Card.edit.validity");
  const canSendEmailCarnet = usePermission("Card.send");
  const canListCardPerFile = usePermission("Card.byFilterPerFile");
  return (
    <>
      {selected.length > 0 && (
        <div>
          {canValidityCarnet && (
            <MDButton
              onClick={handleOpenValidity}
              style={{ left: "20px", marginTop: "10px" }}
              variant="outlined"
              color="info"
            >
              Cambiar vigencia de carnets
            </MDButton>
          )}
        </div>
      )}
      {isAlert && (
        <MDAlert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          typeAlert={alertType}
          message={alertMessage}
        />
      )}
      <br />
      {canSendEmailCarnet && (
        <>
          <MDButton
            onClick={handleSendMailForAll}
            style={{ left: "20px", marginTop: "10px" }}
            variant="outlined"
            color="info"
          >
            Enviar carnet a todos los usuarios
          </MDButton>
          <MDButton
            onClick={handleSendMail}
            style={{ left: "20px", marginTop: "10px", marginLeft: "1rem" }}
            variant="outlined"
            color="info"
          >
            Enviar carnet a usuarios no notificados
          </MDButton>
        </>
      )}
      {canListCardPerFile && (
        <>
          <DataTable
            table={{
              columns: [
                {
                  Header: "Checkbox",
                  id: "checkbox",
                  accessor: (row) => (
                    <Checkbox
                      checked={selected.indexOf(row.id) !== -1}
                      onChange={() => handleSelect(row.id)}
                    />
                  ),
                  width: "0px",
                  Header: () => (
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < rows.length}
                      checked={rows.length > 0 && selected.length === rows.length}
                      onChange={handleSelectAll}
                    />
                  ),
                },
                { Header: "nombre", accessor: "name" },
                { Header: "documento de identidad", accessor: "dni" },
                { Header: "carnet", accessor: "card_type_name" },
                { Header: "creado", accessor: "created" },
                { Header: "vigencia", accessor: "expired_in" },
                { Header: "estado", accessor: "status" },
                { Header: "Tipo de sangre", accessor: "blood_type" },
                { Header: "Acciones", accessor: "actions" },
              ],
              rows: rows,
            }}
            pageIndexAndPageSize={pageIndexAndPageSize}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
          />
          {error ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                {error}
              </MDTypography>
            </MDBox>
          ) : listCarnets?.length === 0 || !listCarnets ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}
