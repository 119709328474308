/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React, { useEffect, useState } from "react";
import { getCard, getStatusTypes, addCarnet } from "../services/cardService";
import CardListPerUser from "../modules/CardListPerUser";
import ModalAddCarnet from "../modules/ModalAddCarnet";
import { getCardTypes } from "layouts/card_type/services/cardTypeService";

export function CardController({ openBoard, idCardUser, name, onClose }) {
  const [dataCard, setDataCard] = useState();
  const [error, setError] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [openBoardCard, setOpenBoardCard] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [newData, setNewData] = useState({
    status: "",
    created: "",
    expires_in: "",
    card_user_id: idCardUser,
    card_type_id: "",
    email: "",
  });
  const [carnetTypes, setCarnetTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [defaultData, setDefaultData] = useState({});

  // Consulta los carnets del usuario seleccionado y las setea en una variable de estado
  const requestCards = async (id, pageIndex) => {
    const result = await getCard(id, pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      //if (data.length !== 0) {
      //}
      await setDataCard(data);
      await setTotalPages(last_page);
      await setTotalData(total);
      await setEntriesStart(from);
      await setEntriesEnd(to);
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // Cierra el modal que muestra los carnets del usuario elegido
  const handleClose = () => {
    onClose();
    setOpenBoardCard(false);
  };

  // Maneja la paginación para mostrar solo un rango de entradas por página
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Hace la peticion
  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestCardUsers(currentPage, pageSize);
  };

  const getFieldValue = (fieldName) => {
    return newData[fieldName];
  };

  const handleOpenModalAdd = async (event) => {
    setOpenModalAdd(true);
    event.preventDefault();
    setIsDisabled(true);

    const data = {
      status: "",
      created: "",
      expires_in: "",
      card_user_id: idCardUser,
      card_type_id: "",
      email: "",
    };
    setDefaultData(data);

    await requestCarnetTypes(1);
    await requestCarnetStatus(1);
    setNewData(data);
  };

  const handleCloseModalAdd = () => setOpenModalAdd(false);

  // solicitar la lista de los estados de carnets
  const requestCarnetStatus = async (pageIndex) => {
    const result = await getStatusTypes(pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      await setStatusTypes(data);
    } else {
      //setErrorAlert("Error al consultar al servidor");
    }
  };

  // solicitar la lista de los tipos de carnets
  const requestCarnetTypes = async (pageIndex) => {
    const result = await getCardTypes(pageIndex);

    if (result.status !== false) {
      const { data, last_page, total, from, to } = result;
      await setCarnetTypes(data);
    } else {
      setErrorAlert("Error al consultar al servidor");
    }
  };

  //Obtener valor por clave especifica del objeto
  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  // actualizar el objeto de carnet cuando se detecta un cambio en el formulario
  const handleFieldChange = async (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });

    // Comprueba la validez del campo y luego establece la validez en el estado
    handleChangeValid(fieldName, value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validacion antes de enviar
    const isFormValid = validateAllField();
    if (isFormValid === false) {
      return;
    }

    const convertedObject = convertValuesToString(newData);
    // Realiza la acción de enviar el formulario aquí
    requestAddCarnet(newData);
  };

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }
    return convertedObject;
  }

  // enviar información del nuevo carnet al servicio que envia al API
  const requestAddCarnet = async (data) => {
    const result = await addCarnet(data);
    if (result.success !== false) {
      handleCloseModalAdd();
      requestCards(idCardUser, currentPage);
    } else {
      setError("Error al consultar al servidor");
    }
  };

  // Función para validar si algún campo de newData está vacío
  const hasEmptyFields = (data) => {
    return Object.values(data).some((value) => value === "");
  };

  useEffect(() => {
    if (openBoard === true) {
      setOpenBoardCard(openBoard);
      requestCards(idCardUser, currentPage);
    }
  }, [currentPage, idCardUser, openBoard]);

  useEffect(() => {
    if (openBoard === true) {
      if (hasEmptyFields(newData)) {
      } else {
        setIsDisabled(false);
      }
      if (newData.created !== "" && newData.expires_in !== "") {
        validateDate();
      }
    }
  }, [newData]);

  //Validaciones

  // Estado de las validaciones
  const [isFieldValid, setIsFieldValid] = useState({
    card_type_id: true,
    created: true,
    expires_in: true,
    status: true,
    email: true,
  });

  // Setea los valores de las validaciones
  const resetFieldValid = () => {
    setIsFieldValid({
      email: true,
      number_id: true,
      name: true,
      lastname: true,
    });
  };

  // Expresiones regulares para diferentes tipos de campos
  const validationPatterns = {
    card_type_id: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    created: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    expires_in: /^\d{4}-\d{2}-\d{2}$/, // Formato aaaa-mm-dd
    status: /^[\s\S]*\S[\s\S]*$/, // Al menos un carácter que no sea un espacio
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // Formato de correo electrónico
  };

  // Compara el valor del input con la expresion regular requerida
  const validateField = (value, pattern) => {
    return pattern.test(value);
  };

  // Comprueba la validez del campo y luego establece la validez en el estado
  const handleChangeValid = (fieldName, value) => {
    const isFieldValid = validateField(value.trim(), validationPatterns[fieldName]);

    setIsFieldValid((prevValidity) => ({
      ...prevValidity,
      [fieldName]: isFieldValid,
    }));
  };

  // Valida que todos los inputs sean validos antes de enviar
  const validateAllField = () => {
    for (const field in isFieldValid) {
      if (!isFieldValid[field]) {
        return false;
      }
    }
  };

  // Funcion para validar si la fecha de creacion es menor a la de expiracion
  const validateDate = () => {
    if (newData.created !== "" && newData.expires_in !== "") {
      if (newData.created > newData.expires_in) {
        const expirationDate = getFieldValueNew("expires_in");
        setNewData({
          ...newData,
          created: expirationDate,
        });
      }
    }
  };

  return (
    <>
      <CardListPerUser
        openBoard={openBoardCard}
        dataCard={dataCard}
        name={name}
        error={error}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleClose={handleClose}
        handleOpenModalAdd={handleOpenModalAdd}
      ></CardListPerUser>
      <ModalAddCarnet
        open={openModalAdd}
        handleClose={handleCloseModalAdd}
        name={name}
        getFieldValue={getFieldValue}
        carnetTypes={carnetTypes}
        statusTypes={statusTypes}
        handleFieldChange={handleFieldChange}
        isDisabled={isDisabled}
        handleSubmit={handleSubmit}
        isFieldValid={isFieldValid}
      />
    </>
  );
}
