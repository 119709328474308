/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import { FormControl, InputLabel, MenuItem, Pagination, Select, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useState } from "react";
import verDetallesImg from "assets/images/ver_detalles.svg";
import PropTypes from "prop-types";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import usePermission from "hooks/usePermission";

export default function UserCard({
  dataCardUser,
  error,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  handleOpenCardBoard,
  handleOpenModalEdit,
  filter,
  handleFilter,
  handleChange,
  search,
  handleClickSubmit,
  isVisible,
  handleClickDelete,
  handleOpenNew,
}) {
  const canListCarnetPerUser = usePermission("Card.byCardUserId");
  // Verificar permisos
  // const canCreate = usePermission("Crear", "Actividades");
  const canEdit = usePermission("CardUser.edit");
  const canDelete = usePermission("CardUser.delete");
  const canListUser = usePermission("CardUser.all");
  // const canListParticipant = usePermission("Listar", "Participantes");
  return (
    <>
      {/* <MDBox style={{ display: "flex", justifyContent: "flex-end", marginRight: "2vw" }}>
        <MDButton onClick={handleOpenNew} variant="gradient" color="info">
          Crear
        </MDButton>
      </MDBox> */}
      {canListUser && (
        <>
          <DataTable
            table={{
              columns: [
                { Header: "nombre", accessor: "name" },
                { Header: "apellido", accessor: "lastname" },
                { Header: "documento de identidad", accessor: "number_id" },
                { Header: "tipo de sangre", accessor: "blood_type" },
                { Header: "creado", accessor: "create_at" },
                { Header: "actualizado", accessor: "updated_at" },
                { Header: "Acciones", accessor: "actions" },
              ],
              rows: dataCardUser
                ? dataCardUser.map((user) => {
                    return {
                      name: user.name,
                      lastname: user.lastname,
                      number_id: user.number_id,
                      blood_type: user.blood_type,
                      create_at: new Date(user.created_at).toISOString().slice(0, 10),
                      updated_at: new Date(user.updated_at).toISOString().slice(0, 10),
                      actions: (
                        <>
                          {canListCarnetPerUser && (
                            <a
                              data-identifier={user.id}
                              title={"Carnets"}
                              data-name={`${user.name} ${user.lastname}`}
                              onClick={handleOpenCardBoard}
                              style={{ cursor: "pointer" }}
                            >
                              {/*<img src={verTablaImg}></img>*/}
                              {
                                <FindInPageOutlinedIcon
                                  style={{
                                    fontSize: "200px",
                                    width: "24px",
                                    height: "24px",
                                    backgroundImage: "#000000",
                                  }}
                                />
                              }
                            </a>
                          )}
                          {canEdit && (
                            <a
                              data-identifier={user.id}
                              data-name={user.name}
                              data-lastname={user.lastname}
                              data-numberid={user.number_id}
                              data-bloodtype={user.blood_type}
                              data-photo={user.photo}
                              onClick={handleOpenModalEdit}
                              title={"Editar usuario"}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            >
                              <EditOutlinedIcon
                                style={{
                                  fontSize: "200px",
                                  width: "24px",
                                  height: "24px",
                                  backgroundImage: "#000000",
                                }}
                              ></EditOutlinedIcon>
                            </a>
                          )}
                          {canDelete && (
                            <a
                              data-identifier={user.id}
                              data-name={user.name}
                              data-lastname={user.lastname}
                              data-numberid={user.number_id}
                              onClick={handleClickDelete}
                              title={"Borrar usuario"}
                              style={{ marginLeft: "8px", cursor: "pointer" }}
                            >
                              <DeleteForeverOutlinedIcon
                                style={{
                                  fontSize: "200px",
                                  width: "24px",
                                  height: "24px",
                                  backgroundImage: "#000000",
                                }}
                              ></DeleteForeverOutlinedIcon>
                            </a>
                          )}
                        </>
                      ),
                    };
                  })
                : [],
            }}
            pageIndexAndPageSize={pageIndexAndPageSize}
            canSearch={false}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
          />
          {error ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                {error}
              </MDTypography>
            </MDBox>
          ) : dataCardUser?.length === 0 || !dataCardUser ? (
            <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MDTypography
                style={{ left: "20px", fontSize: "20px" }}
                variant="button"
                color="secondary"
                fontWeight="regular"
              >
                No hay datos
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
                <MDTypography
                  style={{ left: "20px" }}
                  variant="button"
                  color="secondary"
                  fontWeight="regular"
                >
                  Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
                </MDTypography>
              </MDBox>
              <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                ></Pagination>
              </Stack>{" "}
            </>
          )}
        </>
      )}
    </>
  );
}
